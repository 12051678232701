import {
    $, addFilter, addAction, INIT,
} from '@situation/setdesign.util';

function onAfterChange(event) {
    if (event.property.name === 'position') {
        SetDesign.updateLayout();
    }
}

addAction(INIT, () => { $('.book-slider').on('changed.owl.carousel', onAfterChange); });
addFilter('content-slider/owl-settings', (config, $slider) => ($slider.hasClass('book-slider') ? {
    ...config,
    smartSpeed: 50,
    responsive: {
        0: {
            items: 1,
        },
        768: {
            items: 2, slideBy: 2,
        },
    },
} : config), 90);
